import React, { useState, useEffect } from "react";
import { Button, FormGroup, FormControl, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import LoginView from "./LoginView";
import { AppContext } from "../../App";
import DOMPurify from 'dompurify';

const Login = () => {
  const [value, setValue] = useState("");
  const [isValidated, setIsValidated] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isInValid, setIsInValid] = useState(false);
  const navigate = useNavigate();
  const appContext = React.useContext(AppContext);
  const appData = appContext.data;

  useEffect(() => {
    axios.get("/api/staticdata").then((data) => {
      if (data.data && data.data.message && data.data.message.length) {
        const stringData = data.data.message;
        const stringObj = {};
        stringData.forEach((element) => {
          stringObj[Object.keys(element)[0]] = element[Object.keys(element)[0]];
        });
        appContext.dispatch({ type: "SET_APPLICATION_TEXT", data: stringObj });
      }
    });
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!value || !value.length) {
      setErrorMessage("Email is required");
      setIsInValid(true);
      return null;
    }
    axios
      .post(
        "/api/validateemail",
        { email: value },
        {
          withCredentials: true,
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        /**
        * Issue-5873: Allow invalid usernames on login page to go forward to access code page.
        * When an invalid email is passed forward, then parentId is null and all access codes attempts will be "invalid".
        * This obfuscates whether an email is valid or invalid in the system.
        */
        const data = res.data.message;
        navigate("/accesscode", {
          state: {
            accessCodeExists: data.validAccessCodeExistsForParent,
            parentId: data.parentId,
            parentEmail: data.parentEmail
          }
        });
        return null;
    });
    setErrorMessage("Not a valid email");
    setIsValidated(false);
  };

  const handleChange = (event) => {
    setIsInValid(false);
    setValue(event.target.value);
  };

  return (
    <React.Fragment>
      <LoginView>
        <div className="login__helpinfo">
          <p
            className="h5 m-3"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(appData.staticText.parentportal_email_page_line1),
            }}
          ></p>
          <p>{appData.staticText.parentportal_email_page_line2 || ""}</p>
          <p>{appData.staticText.parentportal_email_page_line3 || ""}</p>
        </div>
        <Form noValidate validated={isValidated} onSubmit={handleSubmit}>
          <FormGroup className="login__row test-start">
            <Form.Label>
              {appData.staticText.parentportal_emailform_text}
            </Form.Label>
            <FormControl
              type="email"
              required
              className="login__input name  "
              placeholder="Email Address"
              value={value}
              onChange={handleChange}
              isInvalid={isInValid}
              aria-label="Email Address"
            />
            <Form.Control.Feedback type="invalid">
              {errorMessage}
            </Form.Control.Feedback>
          </FormGroup>
          <Button
            type="submit"
            className="login__submit"
            disabled={!value || !value.length}
            block
          >
            {appData.staticText.parentportal_login_btn_text}
          </Button>
        </Form>
      </LoginView>
    </React.Fragment>
  );
};

export default Login;
