import React from "react";
import { Row } from "react-bootstrap";
import { Popup } from "@progress/kendo-react-popup";
import DOMPurify from 'dompurify';

const ReportInfo = (props) => {
  const anchor = React.useRef(null);
  const [show, setShow] = React.useState(false);
  var reportTypeTexts = JSON.parse(props.staticText.parentportal_reporttype_texts);
  let showActGuide = props.gridData.find(row => (props.staticText.parentportal_actscoring_grades.includes(row.grade)
    && row.reporttype === reportTypeTexts['Summative']));

  const foundIEPooltype = props.gridData.find((value) => value.pooltype === props.staticText.parentportal_IE_pooltype);
  let DLMGuideLink = foundIEPooltype ? props.staticText.parentportal_DLM_IE_guide : props.staticText.parentportal_DLM_YE_guide;

  const showGuideDescriptions = () => {
    if (show) {
      var popupElement = document.getElementsByClassName('k-animation-container')[0];
      var guideDescriptionsElement = document.getElementById('parentGuideDescriptions');
      var closeDropdownId = window.setInterval(() => {
        // ensure that the popup's opening animation is complete
        if (guideDescriptionsElement.contains(popupElement) && document.getElementsByClassName('k-animation-container-shown').length === 1) {
          // Move the element back to the end of the document's body so that it can be removed safely
          document.body.appendChild(guideDescriptionsElement.removeChild(popupElement));
          window.dispatchEvent(new Event('resize'));
          window.setTimeout(() => { // wait for the position of the popup to be adjusted before closing it
            setShow(false);
          }, 20)
          window.clearInterval(closeDropdownId);
        }
      }, 20)
    }
    else {
      setShow(true);
      var openDropdownId = window.setInterval(() => {
        // wait for the popup to complete its opening animation
        if (document.getElementsByClassName('k-animation-container').length === 1 && document.getElementsByClassName('k-animation-container-shown').length === 1) {
          // By default, the popup is created at the end of the document's body (right before the closing </body> tag)
          // Since we need to preserve tab order, the popup is moved to its logical position in the DOM structure
          var popupElement = document.getElementsByClassName('k-animation-container')[0];
          var guideDescriptionsElement = document.getElementById('parentGuideDescriptions');
          guideDescriptionsElement.appendChild(document.body.removeChild(popupElement));
          // send a resize event to readjust the position of the popup after it has been moved
          window.dispatchEvent(new Event('resize'));
          window.clearInterval(openDropdownId);
        }

      }, 20)

    }
  };

  return (
    <div id="parentGuideDescriptions">
      <Row>
        <h4 className="mt-4 w-auto">
          <u>{props.header}</u>
        </h4>
        {(props.guideLink ?
          <div className="w-auto">
            <button className="showGuideDescriptionsButton"
              onClick={showGuideDescriptions} ref={anchor}
            >{show ? '- ' + props.staticText.parent_portal_reportguidelevel_hide
              : '+ ' + props.staticText.parent_portal_reportguidelevel_show}
            </button>
            <Popup anchor={anchor.current} show={show}>
              <span
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(props.gridData[0].assessmentprogram === 'DLM' ?
                    DLMGuideLink : props.guideLink, { ADD_ATTR: ['target'] })
                }}>
              </span>
              {(showActGuide ?
                <span
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(props.staticText.parentportal_actscoring_guide, { ADD_ATTR: ['target'] }),
                  }}>
                </span> : '')
              }
            </Popup>
          </div> : '')
        }
      </Row>
      <Row>
        <p
          className="mb-4"
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(props.item),
          }}
        ></p>
        <p dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(props.reportNotGeneratedDescription),
        }} ></p>
      </Row>
    </div>
  );
};

export default ReportInfo;
