import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { AppContext } from "../App";

const ProtectedRoute = () => {
  const appContext = React.useContext(AppContext);
  const data = appContext.data;
  // TODO check for token validity
  return data && data.parent && data.parent.id ? (
    <Outlet />
  ) : (
    <Navigate to="/login" />
  );
};
export default ProtectedRoute;
