import React from "react";
import { Col, Row, Container, Card } from "react-bootstrap";
import "./ReportsBody.css";
import DOMPurify from 'dompurify';

const ReportPerformanceLevel = (props) => {
  const performanceLevelList = Object.keys(props.item).map((level, index) => {
    return (
      <Col key={index} className="performancelevels">
        <Card className="mb-2 mt-2 ">
          <Card.Body>
            <Card.Text>
              <b>{level}: </b>
              <span
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(props.item[level]),
                }}
              ></span>
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>
    );
  });
  return (
    <Container>
      <h5 className="text-center">Performance Levels</h5>
      <Row className="mb-2">{performanceLevelList}</Row>
    </Container>
  );
};

export default ReportPerformanceLevel;
