import React, { useState, useEffect } from "react";
import kite_home from "../images/kite_home.JPG";
import "./Home.css";
import HeaderNav from "./HeaderNav";
import Footer from "./Footer";
import { AppContext } from "../App";
import { Card, Modal, Button } from "react-bootstrap";
import { getHomeData, getStudentReports, transformObj } from "../services";
import { useNavigate } from "react-router-dom";
import { isMobileOnly } from "react-device-detect";
import DOMPurify from 'dompurify';

const Home = () => {
  const appContext = React.useContext(AppContext);
  const appData = appContext.data;
  const [showErrorModal, setShowErrorModal] = useState(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!appData.students.length) {
      setLoading(true);
      getHomeData(appData.parent.id)
        .then((res) => {
          setLoading(false);
          const descriptions = res.data.message.homePageDetails.descriptions;
          let assessmentDescriptions = [];
          const homeStaticText = {};
          const assessProgData =
            res.data.message.homePageDetails.assessmentprogramList;
          const userAssessmentPrograms = assessProgData
            ? assessProgData.map((item) => {
              const assessment =
                typeof item === "string" ? JSON.parse(item) : item;
              return assessment.apName;
            })
            : null;
          if (userAssessmentPrograms != null) {
            descriptions.forEach((des) => {
              if (userAssessmentPrograms.indexOf(des.attrname) > -1) {
                assessmentDescriptions.push(des);
              } else if (
                des.attrcode === "parentportal_home_desc_line2" ||
                des.attrcode === "parentportal_home_desc_line1"
              ) {
                homeStaticText[des.attrcode] = des.attrvalue;
              }
            });
          } else {
            descriptions.forEach((des) => {
              homeStaticText[des.attrcode] = des.attrvalue;
            });
          }
          //Sorting assessment program descriptions alphabetically in homepage
          assessmentDescriptions = assessmentDescriptions.sort((a, b) => {
            return a.attrname.localeCompare(b.attrname);
          });
          appContext.dispatch({
            type: "SET_ASSESMENTPROGRAMS",
            data: assessmentDescriptions,
          });
          appContext.dispatch({
            type: "SET_APPLICATION_TEXT",
            data: { ...appData.staticText, ...homeStaticText },
          });
          const studentData = res.data.message.homePageDetails.studentList;
          const students = studentData
            ? studentData.map((student) =>
              typeof student === "string" ? JSON.parse(student) : student
            )
            : null;
          appContext.dispatch({
            type: "SET_STUDENTS",
            data: students ? students : null,
          });
        })
        .catch((err) => {
          setShowErrorModal(err.message);
          setLoading(false);
        });
    }
  }, []);

  const getReports = (e, studentId) => {
    e.preventDefault();
    getStudentReports(studentId)
      .then((res) => {
        const transformedData = transformObj(res.data.message);
        appContext.dispatch({
          type: "ADD_STUDENTREPORTS",
          data: transformedData,
        });
        appContext.dispatch({
          type: "ADD_LOG",
          data: {
            type: "ACCESS_STUDENT_DETAILS",
            logData: `Parent ${appData.parent.email} accessed Student ${transformedData.name}’s report details`,
            districtid: transformedData.districtid,
            schoolyear: transformedData.schoolyear,
          },
        });
        navigate(`/studentreports/${studentId}`);
      })
      .catch((err) => setShowErrorModal(err.message));
  };
  const itemList = (items) => {
    const cards = items.map((item) => {
      return (
        <Card
          style={
            isMobileOnly
              ? { width: "98%", marginTop: "8px" }
              : { width: "24%", marginTop: "8px" }
          }
          className="me-2"
          key={item.studentid}
        >
          <Card.Body>
            <Card.Title>{item.studentname}</Card.Title>
            {appData.assesmentPrograms &&
              appData.assesmentPrograms.length &&
              item.student_ap ? (
              <a
                href={`/studentreports/${item.studentid}`}
                onClick={(e) => getReports(e, item.studentid)}
                className="link_color"
              >
                {appData.staticText.parentportal_viewreporttext}
              </a>
            ) : (
              <i>{appData.staticText.parentportal_noreportforstudent}</i>
            )}
          </Card.Body>
        </Card>
      );
    });
    return cards;
  };
  return (
    <div className="container">
      <HeaderNav />
      <div>
        <p
          className="mt-3"
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(appData.staticText.parentportal_home_desc_line1),
          }}
        ></p>
        {appData.assesmentPrograms && appData.assesmentPrograms.length ? (
          appData.assesmentPrograms.map((ap) => (
            <Card className="mb-2 mt-2" key={ap.attrname}>
              <Card.Body>
                <Card.Title>{ap.attrname}</Card.Title>
                <Card.Text
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(ap.attrvalue),
                  }}
                ></Card.Text>
              </Card.Body>
            </Card>
          ))
        ) : appData.students && appData.students.length ? (
          <div>{appData.staticText.parentportal_noreport_text}</div>
        ) : null}
      </div>
      <br />
      {!loading &&
        appData.assesmentPrograms &&
        appData.assesmentPrograms.length ? (
        <p
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(appData.staticText.parentportal_home_desc_line2),
          }}
        ></p>
      ) : null}

      {!loading && appData.students && appData.students.length ? (
        <div
          className="mb-2 row"
          style={isMobileOnly ? { display: "inline" } : { display: "flex" }}
        >
          {itemList(appData.students)}
        </div>
      ) : (
        <div>
          {appData.students === null
            ? appData.staticText.parentportal_nostudent_text
            : ""}
        </div>
      )}
      <br />
      <div>
        <img src={kite_home} alt="logo" className="app-logo" />
      </div>
      <div className="app-footer">
        <Footer />
      </div>
      <Modal
        show={showErrorModal ? true : false}
        onHide={() => setShowErrorModal(null)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>{showErrorModal}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowErrorModal(null)}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Home;
