import React from "react";
import ReportInfo from "./ReportInfo";
import ReportPerformanceLevel from "./ReportPerformanceLevel";
import ReportGrid from "./ReportGrid";
import { Row, Col, Container } from "react-bootstrap";

const ReportsBody = (props) => {
    
  return (
    <div>
      <Container>
        <Row>
          <Col>
            <ReportInfo
                          item={props.data.reportDescription}
                          reportNotGeneratedDescription={(props.data.excludedgrades  && props.data.reporttype === "KAP Summative" && props.data.excludedgrades[props.data.contentarea]
                                                  )}
              header={props.header}
              guideLink={props.guideLink}
              gridData={props.data.reports}
              staticText={props.staticText}
            />
          </Col>
        </Row>
        {props.data.perfLevelDescriptions &&
          Object.keys(props.data.perfLevelDescriptions).length && (
            <Row>
              <Col>
                <ReportPerformanceLevel
                  item={props.data.perfLevelDescriptions}
                />
              </Col>
            </Row>
          )}
        <Row>
          <Col>
            <ReportGrid item={props.data.reports} audioFiles={props.data.audioDescriptions} currentreportyear={props.data.currentreportyear} />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ReportsBody;
