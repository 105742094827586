import axios from "axios";

const getCookie = (cname) => {
  const name = cname + "=";
  const ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

axios.interceptors.request.use(function (config) {
  const token = getCookie("token");
  if (token !== "") {
    config.headers.Authorization = token;
  }

  return config;
});
export const getHomeData = (parentId) =>
  new Promise((res, rej) => {
    axios
      .get(`/api/home/${parentId}`)
      .then((data) => res(data))
      .catch((err) => {
        rej({
          message: "An error occured while fetching the data, Please try again",
        });
      });
  });

export const getStudentReports = (studentId) =>
  new Promise((res, rej) => {
    axios
      .get(`/api/reports/${studentId}`)
      .then((data) => res(data))
      .catch((err) => {
        rej({
          message: "An error occured while fetching the data, Please try again",
        });
      });
  });

const groupBy = (xs, key) => {
  return xs.reduce((rv, x) => {
    rv[x[key]] = rv[x[key]] || {
      subjectname: x["subjectname"],
      reporttype: x["reporttype"],
      contentarea: x["contentarea"],
      reports: [],
    };
    rv[x[key]].reports.push(x);
    return rv;
  }, {});
};
export const transformObj = (d) => {
    d.reportPageDetails.forEach((grid, i) => {
      
    grid.gridReportDetails = Object.values(
      groupBy([].concat(...grid.gridReportDetails), "contentarea")
    );
    if (Object.keys(grid.perfLevelDescriptions).length) {
      grid.perfLevelDescriptions.attrvalue = JSON.parse(
        grid.perfLevelDescriptions.attrvalue
      );
    }
    if (Object.keys(grid.reportDescription).length) {
      grid.reportDescription.attrvalue = JSON.parse(
        grid.reportDescription.attrvalue
      );
    }
    if (Object.keys(grid.audioDescriptions).length) {
      grid.audioDescriptions.attrvalue = JSON.parse(
        grid.audioDescriptions.attrvalue
      );
    }

    // const attrcode = grid.perfLevelDescriptions.attrcode.split("_");
    // if (attrcode.indexOf("summative") > -1) {
    //   grid.perfLevelDescriptions.attrvalue = JSON.parse(
    //     grid.perfLevelDescriptions.attrvalue
    //   );
    //   grid.reportDescription.attrvalue = JSON.parse(
    //     grid.reportDescription.attrvalue
    //   );
    // }
    const reports = grid.gridReportDetails.map((rep) => {
      const newRep = { ...rep };
      if (Object.keys(grid.perfLevelDescriptions).length) {
        newRep.perfLevelDescriptions = grid.perfLevelDescriptions.attrvalue
          .commonDescription
          ? grid.perfLevelDescriptions.attrvalue.commonDescription
          : grid.perfLevelDescriptions.attrvalue[rep.contentarea];
      }

      if (Object.keys(grid.audioDescriptions).length) {
        newRep.audioDescriptions = grid.audioDescriptions.attrvalue
          .commonDescription
          ? grid.audioDescriptions.attrvalue.commonDescription
          : grid.audioDescriptions.attrvalue[rep.contentarea];
      }

      if (Object.keys(grid.currentreportyear)) {
        newRep.currentreportyear = grid.currentreportyear;
      }

      if (
        Object.keys(grid.reportDescription).length &&
        !grid.reportDescription.attrvalue.commonDescription
      ) {
        newRep.reportDescription =
          grid.reportDescription.attrvalue[rep.contentarea];
      } else {
        newRep.reportDescription =
          grid.reportDescription.attrvalue.commonDescription;
        }

        newRep.excludedgrades = grid.excludedgrades;
      return newRep;
    });
    grid.gridReportDetails = reports;
  });
  const newObj = {
    id: d.student.id,
    name: d.student.studentName,
    districtid: d.student.districtid,
    schoolyear: d.student.schoolyear,
    reporttype: d.reportPageDetails,
    
  };
  return newObj;
};

export const insertParentActivity = (activity) =>
  new Promise((res, rej) => {
    axios
      .post(`/api/parentActivity`, activity)
      .then((data) => res(data))
      .catch((err) => {
        rej({
          message: "An error occured while fetching the data, Please try again",
        });
      });
  });