import React from "react";
import { Link } from "react-router-dom";
import { FormGroup, Form } from "react-bootstrap";
import { AppContext } from "../../App";
import Footer from "../Footer.js";
import logo from "../../images/parent_portal_logo.svg";

const RegistrationCodeAccepted = () => {
    const appContext = React.useContext(AppContext);
    const appData = appContext.data;
    return (
        <div className="login">
            <div class="login__form text-center">
                <div className="login__kitelogo mb-4">
                    <img src={logo} alt="Kite logo" />
                </div>
                <React.Fragment>
                    <Form>
                        <FormGroup className="login__row test-start">
                            <Form.Label>
                                {appData.staticText.parentportal_registrationacceptedform_text}
                            </Form.Label>
                        </FormGroup>
                        <p className="login__needhelp mt-4">
                            <Link className="link_color" to="/login">
                                {appData.staticText.parentportal_loginreturn_text}
                            </Link>
                        </p>
                        <p className="login__needhelp mt-4">
                            <Link className="link_color" to="/register">
                                {appData.staticText.parentportal_registrationanotherstudent_text}
                            </Link>
                        </p>
                    </Form>
                </React.Fragment>
                <Footer className="app-footer-login mb-0 mt-4" />
            </div>
        </div>
    );
};
export default RegistrationCodeAccepted;