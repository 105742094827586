import React, { useState, useEffect } from "react";
import { Button, FormGroup, FormControl, Form, Modal } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import LoginView from "./LoginView";
import axios from "axios";
import { AppContext } from "../../App";
import DOMPurify from 'dompurify';

const AccessCode = () => {
  const [value, setValue] = useState("");
  const [isValidated, setIsValidated] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isInValid, setIsInValid] = useState(false);
  const [showRequestButton, setShowRequestButton] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const appContext = React.useContext(AppContext);
  const appData = appContext.data;

  useEffect(() => {
    if (appContext.data.parent && appContext.data.parent.id) {
      navigate("/home");
    } else {
      // TODO logout the user
    }
  }, [appContext.data.parent, location]);

  useEffect(() => {
    if (!location || !location.state || !location.state.parentEmail) {
      navigate("/login");
    }
  }, []);


  const { parentId, accessCodeExists } = location.state || {}; // set parentId and accessCodeExists to undefined if location.state is null

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!value || !value.length) {
      setErrorMessage("Access Code is required");
      setIsInValid(true);
      return null;
    }

    /**
     * Issue-5873: Allow invalid usernames on login page to go forward to access code page.
     * When an invalid email is passed forward, then parentId is null and all access codes attempts will be "invalid".
     * This obfuscates whether an email is valid or invalid in the system.
     */
    if (parentId === null) {
      setErrorMessage("Not a valid Access Code");
      setIsInValid(true);
      return null;
    }

    axios
      .get(`/api/accesscode/${parentId}/${value}`, {
        withCredentials: true,
      })
      .then((res) => {
        const data = res.data.message;
        if (data.accessCodeValid) {
          appContext.dispatch({
            type: "PARENT_LOGIN",
            data: {
              email: location.state.parentEmail,
              id: data.parentid,
              token: res.data.token,
              surveyTaken: res.data.surveyTaken,
              surveyUrl: res.data.surveyUrl,
            },
          });
          appContext.dispatch({
            type: "ADD_LOG",
            data: {
              type: "PARENT_LOGGED_IN",
              logData: `Parent ${location.state.parentEmail} logged in`,
            },
          });
          const cookieDate = new Date();
          cookieDate.setDate(cookieDate.getDate() + 1);
          document.cookie = `token=${res.data.token}; expires=${cookieDate} path=/`;
          return null;
        } else {
          setErrorMessage(
            data.errorMessage ? data.errorMessage.description : ""
          );
          setShowRequestButton(true);
          setIsInValid(true);
          return null;
        }
      });
    setErrorMessage("Not a valid Access Code");
    setIsValidated(false);
  };

  const handleChange = (event) => {
    setIsInValid(false);
    setValue(event.target.value);
  };

  const requestAccessCode = () => {
    axios
      .post(`/api/generateaccesscode`, {
        email: location.state.parentEmail,
        parentid: location.state.parentId,
      })
      .then((res) => {
        setShowModal(true);
      });
  };
  return (
    <React.Fragment>
      <LoginView>
        <p className="accesscode_div">
          {!accessCodeExists && (
            <span
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(appData.staticText.parentportal_accesscode_page1_line1),
              }}
            ></span>
          )}
          <span
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(appData.staticText.parentportal_accesscode_page1_line2),
            }}
          ></span>
        </p>
        <Form noValidate validated={isValidated} onSubmit={handleSubmit}>
          <FormGroup className="login__row text-start">
            <Form.Label>
              {appData.staticText.parentportal_accesscodeform_text}
            </Form.Label>
            <FormControl
              type="email"
              required
              className="login__input name"
              placeholder="Access Code"
              value={value}
              onChange={handleChange}
              isInvalid={isInValid}
              aria-label="Access Code"
            />
            <Form.Control.Feedback type="invalid">
              {errorMessage}
            </Form.Control.Feedback>
          </FormGroup>
          <Button
            type="submit"
            className="login__submit"
            disabled={!value || !value.length}
            block
          >
            {appData.staticText.parentportal_accesscode_btn_text}
          </Button>
          {!accessCodeExists && (
            <p className="accesscode_div">
              <span>
                {appData.staticText.parentportal_accesscode_page1_line3}
              </span>
              <br></br>
              <span>
                {appData.staticText.parentportal_accesscode_page1_line4}
                <button
                  type="button"
                  href=""
                  className="buttonLink"
                  variant="secondary"
                  onClick={requestAccessCode}
                >
                  here.
                </button>{" "}
              </span>
            </p>
          )}{" "}
          {accessCodeExists && (
            <Button
              type="button"
              className="request"
              block
              variant="secondary"
              onClick={requestAccessCode}
            >
              {appData.staticText.parentportal_resend_accesscode_btn_text}
            </Button>
          )}
        </Form>
      </LoginView>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            {appData.staticText.parentportal_accesscodesent_modaltext}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {appData.staticText.parentportal_accesscodesent_text}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default AccessCode;
