import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import KapPdfHighlighting from './kapPdfHighlighting';
import { AppContext } from "../../App";
import './PdfHighlighting.css';
import './kapPdfHighlighting.css';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const KapReportAudio = (props) => {
    const appContext = React.useContext(AppContext);
    const [noOfPages, setNoOfPages] = useState(null);
    // To load the pages based on available number of pages
    function onDocumentLoadSuccess({ numPages }) {
        setNoOfPages(numPages);
    }
    return (
        <div className='container-pdf' >
            <div className='row middle-xs' >
                <div className="col">
                    <Document file={appContext.data.selectedReport.filepath} onLoadSuccess={onDocumentLoadSuccess} >
                        {Array.from(new Array(noOfPages),
                            (el, index) => (<Page size="A3" pageNumber={index + 1}
                                width={100} scale={10} alignment={'center'} key={index}
                            />),
                        )}
                    </Document>
                    <KapPdfHighlighting />
                </div>
            </div>
        </div>
    );
};

export default KapReportAudio;