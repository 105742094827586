import React from "react";
import HeaderNav from "./HeaderNav";
import { AppContext } from "../App";
import DOMPurify from 'dompurify';

const Contact = () => {
  const appContext = React.useContext(AppContext);
  const appData = appContext.data;

  return (
    <div className="container">
      <HeaderNav />
      <div dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(appData.staticText.parentportal_helppage_content, { ADD_ATTR: ['target'] })
      }}
      ></div>
    </div>
  );
};

export default Contact;
