import React, { useReducer } from "react";
import {
  BrowserRouter as Router,
  Route,
  Navigate,
} from "react-router-dom";

import "./App.css";
import Login from "./components/auth/Login";
import AccessCode from "./components/auth/AccessCode";
import VerifyRegistrationCode from "./components/auth/VerifiyParentCode";
import RegistrationCodeAccepted from "./components/auth/ParentCodeAccepted";

import Home from "./components/Home";
import Contact from "./components/Contact";
import ReportsParent from "./components/Reports/ReportsParent";
import KapReportAudio from "./components/Reports/KapReportAudio";
import DlmReportAudio from "./components/Reports/DlmReportAudio";

import "@progress/kendo-theme-default/dist/all.css";
import '@progress/kendo-font-icons/dist/index.css'

// these files need to be imported for react-pdf v6 upgrade to remove extra space between pages & text in the background
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import ProtectedRoute from "./components/ProtectedRoute";
import { insertParentActivity } from "./services";
import { Routes } from "react-router";

export const AppContext = React.createContext();

const initialState = {
  parent: {},
  assesmentPrograms: [],
  students: [],
  studentReports: [],
  staticText: {},
  logData: [],
  selectedReport: {},
  audioFiles: []
};
const appReducer = (state, action) => {
  let newState;
  switch (action.type) {
    case "PARENT_LOGIN":
      newState = { ...state, parent: action.data };
      break;
    case "PARENT_LOGOUT":
      sessionStorage.clear();
      document.cookie = "token=;expires=Thu, 01 Jan 1970 00:00:01 GMT;";
      newState = initialState;
      break;
    case "SET_APPLICATION_TEXT":
      newState = { ...state, staticText: action.data };
      break;
    case "SET_ASSESMENTPROGRAMS":
      newState = { ...state, assesmentPrograms: action.data };
      break;
    case "SET_SELECTED_REPORT_DETAILS":
      newState = { ...state, selectedReport: action.data };
      break;
    case "SET_AUDIOFILES":
      newState = { ...state, audioFiles: action.data };
      break;
    case "SET_STUDENTS":
      newState = { ...state, students: action.data };
      break;
    case "ADD_STUDENTREPORTS":
      const studentIds = state.studentReports.map((student) => student.id);
      const newStudentReports = [...state.studentReports];
      if (studentIds.indexOf(action.data.id) > -1) {
        newStudentReports[studentIds.indexOf(action.data.id)] = action.data;
      } else {
        newStudentReports.push(action.data);
      }
      newState = {
        ...state,
        studentReports: [...newStudentReports],
      };
      break;
    case "ADD_LOG":
      const newLog = {
        parentid: state.parent.id,
        parentemail: state.parent.email,
        type: action.data.type,
        timestamp: new Date().getTime(),
        description: action.data.logData,
        districtid: action.data.districtid,
        schoolyear: action.data.schoolyear,
      };
      if (action.data.type === 'DOWNLOAD_REPORT_FROM_GRID' || action.data.type === 'PLAY_REPORT_TTS') {
        newLog.reportid = action.data.reportid;
        newLog.reporttype = action.data.reporttype;
      }
      newState = {
        ...state,
        logData: [...state.logData, newLog],
      };
      insertParentActivity(newLog);

      break;
    default:
      newState = state;
      break;
  }
  sessionStorage.setItem("data", JSON.stringify(newState));
  return newState;
};

const App = () => {
  const sessionData = JSON.parse(sessionStorage.getItem("data"));
  const [appData, dispatch] = useReducer(
    appReducer,
    sessionData ? sessionData : initialState
  );

  return (
    <AppContext.Provider value={{ data: appData, dispatch }}>
      <div className="App">
        <Router>
          <Routes>
            <Route exact path="/" element={<Navigate to="/login" />} />
            <Route path="/login" element={<Login />} />
            <Route path="/accesscode" element={<AccessCode />} />
            <Route path="/register" element={<VerifyRegistrationCode />} />
            <Route path="/acceptedregistration" element={<RegistrationCodeAccepted />} />
            <Route path="/contact" element={<Contact />} />
            <Route element={<ProtectedRoute />}>
              <Route path="/home" element={<Home />} />
              <Route path="/studentreports/:id" element={<ReportsParent />} />
              <Route path="/kap_summative/audioreports" element={<KapReportAudio />} />
              <Route path="/dlm/audioreports" element={<DlmReportAudio />} />
            </Route>
          </Routes>
        </Router>
      </div>
    </AppContext.Provider>
  );
};

export default App;