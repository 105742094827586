import React, { useState } from "react";
import "./PdfHighlighting.css";
import "./dlmPdfHighlighting.css";
import { AppContext } from "../../App";

const DlmPdfHighlighting = (props) => {
    const appContext = React.useContext(AppContext);
    const [highlightArray, setHighlightArray] = useState({});
    const appData = appContext.data;
    const fileArray = appData.audioFiles.reportAudioFiles;
    const reportDetails = appData.selectedReport;
    reportDetails["email"] = appData.parent.email;

    let audioDivs = [];
    fileArray.forEach((item, index) => {
        audioDivs.push(<audio className={`audio-element${index}`} preload="auto">
            <source src={item.audioFile} ></source>
        </audio>)
    });

    //Used to Navigate page dynamically while playing the audio
    const navigatePageHandler = () => {
        setTimeout(() => {
            window.location.href = "/dlm/audioreports#highlighting";
        }, 100);
    }

    const clickHighlight = (e) => {
        appData.audioFiles.reportAudioFiles.forEach((value, index) => {
            highlightArray[`highlightText${index}`] = false
        });
        highlightArray['disableButton'] = false
        //Adding Parent Activity History record for Play button.
        appContext.dispatch({
            type: "ADD_LOG",
            data: {
                type: "PLAY_REPORT_TTS",
                logData: `Parent ${reportDetails.email} played ${reportDetails.reporttype} ${reportDetails.subjectname} ${reportDetails.schoolyear} report for reportid : ${reportDetails.reportid}`,
                reporttype: reportDetails.reporttype,
                reportid: reportDetails.reportid
            },
        });

        let audioElement = [];
        fileArray.forEach((value, index) => {
            let indexElement = document.getElementsByClassName(`audio-element${index}`);
            audioElement.push(indexElement[0]);
        });

        let timeInterval = [1000];
        const playInterval = 2000; // to add interval between the audios
        audioElement.forEach((value, index) => {
            timeInterval[index + 1] = timeInterval[index] + (value.duration) * 1000 + playInterval;
        })

        for (let i = 0; i < audioElement.length; i++) {
            setTimeout(() => {
                setHighlightState(i, fileArray, audioElement);
                if (i !== audioElement.length) audioElement[i].play();
            }, timeInterval[i]);
        }

        const setHighlightState = (i, fileArray, audioElement) => {
            let highlightArray = {}
            fileArray.forEach((value, index) => {
                highlightArray[`highlightText${index}`] = false
            });

            fileArray.forEach(function (value, index) {
                if (i === index) {
                    let highlight = `highlightText${index}`;
                    highlightArray[highlight] = true
                    Object.keys(highlightArray).forEach((value) => {
                        (value === highlight) ?
                            (highlightArray.value = true) : (highlightArray.value = false);
                        highlightArray['disableButton'] = true
                        setHighlightArray(highlightArray)
                        navigatePageHandler();
                        if (index === (fileArray.length - 1)) {
                            stopAudio(audioElement[index].duration)
                        }
                    })
                }
            });
        };
    }

    //to stop the audio
    const stopAudio = (duration) => {
        setTimeout(() => {
            window.location.href = "/dlm/audioreports";
        }, duration * 1000);
    }

    //to exit the report page
    const exitPage = () => {
        setTimeout(() => {
            window.close();
        }, 100);
    }

    return (
        <div >
            {fileArray.map((item, index) => {
                return (
                    highlightArray[`highlightText${index}`] ?
                        (<div id={item.divName}></div>) : (<div id="alert-hidden"></div>)
                )
            })}
            <div className='button-divs'>
                <button className="play-button" title="Play"
                    onClick={() => clickHighlight()} disabled={highlightArray['disableButton']}>
                    <span className="k-icon k-font-icon k-i-play"></span>
                </button>
                <button className="stop-button" title="Stop"
                    onClick={() => stopAudio()}>
                    <span className="k-icon k-font-icon k-i-stop"></span>
                </button>
                <a className="download-button" title="Download" href={appData.selectedReport.filepath} download target="_blank" rel="noreferrer">
                    <span className="k-icon k-font-icon k-i-download"></span>
                </a>
                <button className="exit-button" title="Exit"
                    onClick={() => exitPage()}>
                    <span className="k-icon k-font-icon k-i-close-circle"></span>
                </button>
            </div>
            <div className="element">
                {audioDivs}
            </div>
        </div>
    );
}
export default DlmPdfHighlighting;
