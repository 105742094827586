import React, { useState } from "react";
import { Navbar, Modal, Button } from "react-bootstrap";
import logo from "../images/parent_portal_logo.svg";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../App";
import { isMobileOnly } from "react-device-detect";

const HeaderNav = () => {
  const appContext = React.useContext(AppContext);
  const data = appContext.data;
  const navigate = useNavigate();
  const [survey, setsurvey] = useState(false);
  const parentLogout = () => {
    if (!data.parent.surveyTaken) {
      setsurvey(true);
    } else {
      logout();
    }
  };
  const close = () => {
    setsurvey(false);
  };
  const openSurvey = () => {
    window.open(data.parent.surveyUrl, "_blank");
    logout();
  };
  const logout = () => {
    appContext.dispatch({ type: "PARENT_LOGOUT" });
    navigate("/");
  };
  return (
    <Navbar
      bg="light"
      style={isMobileOnly ? { display: "block" } : { display: "flex" }}
    >
      <Navbar.Brand href="/home">
        <img
          alt="kite-logo"
          src={logo}
          width="280"
          className="d-inline-block align-top"
        />
      </Navbar.Brand>
      <Navbar.Toggle />
      {data.parent.id && (
        <Navbar.Collapse className="justify-content-end">
          <Navbar.Text>
            {data.staticText.parentportal_user_label}{" "}
            <i>
              <b>{data.parent.email ? data.parent.email : ""}</b>
            </i>
            ,{" "}
            <button className="btn btn-toolbar" onClick={parentLogout}>
              {data.staticText.parentportal_signout_text}
            </button>
            <Modal show={survey ? true : false}>
              <Modal.Header closeButton onClick={close}>
                <Modal.Title>
                  {data.staticText.parentportal_survey_title}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {data.staticText.parentportal_survey_text}
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={openSurvey}>
                  {data.staticText.parentportal_takesurvey_yes}
                </Button>
                <Button variant="secondary" onClick={logout}>
                  {data.staticText.parentportal_takesurvey_no}
                </Button>
              </Modal.Footer>
            </Modal>
          </Navbar.Text>
        </Navbar.Collapse>
      )}
    </Navbar>
  );
};
export default HeaderNav;
