import React from "react";
import { Link } from "react-router-dom";
import "./Login.css";
import Footer from "../Footer.js";
import logo from "../../images/parent_portal_logo.svg";
import { AppContext } from "../../App";

const LoginView = (props) => {
  const appContext = React.useContext(AppContext);
  const appData = appContext.data;
  return (
    <div className="login">
      <div className="login__form text-center">
        <div className="login__kitelogo">
          <img src={logo} alt="Kite logo" />
        </div>

        {props.children}
        <p className="login__needhelp mt-4">
          <Link className="link_color" to="/register">
            {appData.staticText.parentportal_registerstudent_text}
          </Link>
        </p>
        <p className="login__needhelp mt-4">
          <Link className="link_color" to="/contact">
            Need Help?
          </Link>
        </p>
        <Footer className="app-footer-login mb-0" />
      </div>
    </div>
  );
};
export default LoginView;
