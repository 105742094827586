import React, { useState, useEffect } from "react";
import { Button, FormGroup, FormControl, Form } from "react-bootstrap";
import axios from "axios";
import { AppContext } from "../../App";
import Footer from "../Footer.js";
import logo from "../../images/parent_portal_logo.svg";

const VerifyRegistrationCode = () => {
    const [email, setEmail] = useState("");
    const [code, setCode] = useState("");
    const [ssid, setSSID] = useState("");
    const [isValidated, setIsValidated] = useState(false);
    const [isInValidEmail, setIsInValidEmail] = useState(false);
    const [isInValidCode, setIsInValidCode] = useState(false);
    const [isInValidSSID, setIsInValidSSID] = useState(false);
    const [errorMessageEmail, setErrorMessageEmail] = useState("");
    const [errorMessageCode, setErrorMessageCode] = useState("");
    const [errorMessageSSID, setErrorMessageSSID] = useState("");
    const appContext = React.useContext(AppContext);
    const appData = appContext.data;
    const regEmail = new RegExp(appData.staticText.parentportal_email_regex);

    //Get static data to populate text in verification screen
    useEffect(() => {
        axios.get("/api/staticdata").then((data) => {
            if (data?.data?.message.length) {
                const stringData = data.data.message;
                const stringObj = {};
                stringData.forEach((element) => {
                    stringObj[Object.keys(element)[0]] = element[Object.keys(element)[0]];
                });
                appContext.dispatch({ type: "SET_APPLICATION_TEXT", data: stringObj });
            }
        });
    }, []);

    const handleSubmit = (event) => {
        //ensure all fields have been set
        event.preventDefault();
        if (!email || !email.length) {
            setErrorMessageEmail(appData.staticText.parentportal_email_required);
            setIsInValidEmail(true);
            setIsValidated(false);
            return null;
        }
        if (!regEmail.test(email)) {
            setErrorMessageEmail(appData.staticText.parentportal_email_invalid);
            setIsInValidEmail(true);
            setIsValidated(false);
            return null;
        }
        if (!code || !code.length) {
            setErrorMessageCode(appData.staticText.parentportal_code_required);
            setIsInValidCode(true);
            setIsValidated(false);
            return null;
        }
        if (!ssid || !ssid.length) {
            setErrorMessageSSID(appData.staticText.parentportal_ssid_required);
            setIsInValidSSID(true);
            setIsValidated(false);
            return null;
        }
        //verify registration code and ssid
        axios
            .post(
                "/api/validateregistrationcode",
                {
                    stateStudentId: ssid,
                    registrationCode: code,
                },
            )
            .then((res) => {
                const data = res.data.message;
                //if registration code and ssid are valid register parentemail with student to create new parentstudentrelation record
                if (data.parentportal_verifyregistration) {
                    axios.post(
                        "/api/registerparent",
                        {
                            parentEmail: email,
                            registrationCode: code,
                        },
                    )
                        .then((res) => {
                            //After registration go to success screen
                            const data = res.data.message;
                            if (data === 'success') {
                                window.location.replace("/acceptedregistration");
                            } else {
                                errorResponse(data);
                            }
                        });
                } else {
                    //Report error if input is invalid
                    errorResponse(data);
                }
            });
    };

    const errorResponse = (data) => {
        setErrorMessageCode(
            data.errorMessage ? data.errorMessage.description : appData.staticText.parentportal_invalid_parent_code
        ); setErrorMessageSSID(
            data.errorMessage ? data.errorMessage.description : appData.staticText.parentportal_invalid_parent_code
        );
        setIsInValidCode(true);
        setIsInValidSSID(true);
        return null;
    };

    const handleEmailChange = (event) => {
        setIsInValidEmail(false);
        setEmail(event.target.value);
    };

    const handleCodeChange = (event) => {
        setIsInValidCode(false);
        setCode(event.target.value);
    };

    const handleSSIDChange = (event) => {
        setIsInValidSSID(false);
        setSSID(event.target.value);
    };

    return (
        <div className="login">
            <div class="login__form text-center">
                <div className="login__kitelogo mb-4">
                    <img src={logo} alt="Kite logo" />
                </div>
                <React.Fragment>
                    <Form noValidate validated={isValidated} onSubmit={handleSubmit}>
                        <FormGroup className="login__row test-start">
                            <Form.Label>
                                {appData.staticText.parentportal_emailform_text}
                            </Form.Label>
                            <FormControl
                                type="email"
                                required
                                className="login__input name  "
                                placeholder="Email Address"
                                value={email}
                                onChange={handleEmailChange}
                                isInvalid={isInValidEmail}
                                aria-label="Email Address"
                            />
                            <Form.Control.Feedback type="invalid">
                                {errorMessageEmail}
                            </Form.Control.Feedback>
                        </FormGroup>
                        <FormGroup className="login__row test-start">
                            <Form.Label>
                                {appData.staticText.parentportal_codeform_text}
                            </Form.Label>
                            <FormControl
                                type="code"
                                required
                                className="login__input name  "
                                placeholder={appData.staticText.parentportal_registrationcode_text}
                                value={code}
                                onChange={handleCodeChange}
                                isInvalid={isInValidCode}
                                aria-label={appData.staticText.parentportal_registrationcode_text}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errorMessageCode}
                            </Form.Control.Feedback>
                        </FormGroup>
                        <FormGroup className="login__row test-start">
                            <Form.Label>
                                {appData.staticText.parentportal_ssidform_text}
                            </Form.Label>
                            <FormControl
                                type="ssid"
                                required
                                className="login__input name  "
                                placeholder="State Student Identifier"
                                value={ssid}
                                onChange={handleSSIDChange}
                                isInvalid={isInValidSSID}
                                aria-label="State Student Identifier"
                            />
                            <Form.Control.Feedback type="invalid">
                                {errorMessageSSID}
                            </Form.Control.Feedback>
                        </FormGroup>
                        <Button
                            type="submit"
                            className="login__submit"
                            disabled={(!email || !email.length) || (!code || !code.length) || (!ssid || !ssid.length)}
                            block
                        >
                            {appData.staticText.parentportal_login_btn_text}
                        </Button>
                    </Form>
                </React.Fragment>
                <Footer className="app-footer-login mb-0 mt-4" />
            </div>
        </div>
    );
};
export default VerifyRegistrationCode;