import React, { useState, useEffect } from "react";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { useParams } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { process } from "@progress/kendo-data-query";
import { GridColumnMenuCheckboxFilter } from "@progress/kendo-react-grid";
import { AppContext } from "../../App";
import { orderBy } from "@progress/kendo-data-query";
import './PdfHighlighting.css';
import { useNavigate } from "react-router-dom";

const GetReports = (data, sort) => {
  return orderBy(data, sort);
};

// DE22508: data contains grades as strings, so they need to be converted to numbers 
//          to ensure proper sorting with grades that have two digits
const ConvertGradeToNumeric = (data) => {
  return data.map(reportObj => {
    // Parse grade value to an integer
    const parsedValue = parseInt(reportObj.grade);
    // Checks if parsing was successful
    // If successful, replace the grade value in the report object with the numeric value
    // If parsing fails (if grade is not a valid number), keep the original grade value
    return { ...reportObj, grade: isNaN(parsedValue) ? reportObj.grade : parsedValue };

  });
};

const CustomCell = (props) => {
  const appContext = React.useContext(AppContext);
  const loc = useParams();
  const navigate = useNavigate();

  const clickLink = (e) => {
    const item = props.dataItem;
    const reportType = item.reporttype.toLowerCase().replace(' ', '_');
    appContext.dispatch({
      type: "ADD_LOG",
      data: {
        type: "DOWNLOAD_REPORT_FROM_GRID",
        logData: `Parent ${appContext.data.parent.email} accessed Student ${loc.id}’s ${item.reporttype} ${item.subjectname} ${item.schoolyear} report. (${item.actualPath})`,
        reporttype: item.reporttype,
        reportid: item.reportid
      },
    });
    appContext.dispatch({
      type: "SET_SELECTED_REPORT_DETAILS",
      data: props.dataItem,
    });
    // 4521 - Temporarily hardcoding to not include audio for Grade 2 Reading reports
    if (props?.dataItem?.grade === 2
      && props?.dataItem?.contentarea === 'ELA'
      && props?.dataItem?.assessmentprogram === 'KAP') {
      setTimeout(() => { window.open(props.dataItem[props.field]) }, 100);
    } else {
      setTimeout(() => {
        (!appContext.data.audioFiles.reportAudioFiles.length || props.dataItem.schoolyear !== appContext.data.audioFiles.currentreportyear) ?
          window.open(props.dataItem[props.field]) : navigate(window.open('/' + reportType + `/audioreports`));
      }, 100);
    }
  };

  return (
    <td>
      <button
        className='pdf-button'
        onClick={clickLink}
        aria-label="student report link"
      >
        {props.dataItem.actualPath ? (
          <span className="k-icon k-font-icon k-i-file-pdf"></span>
        ) : null}
      </button>
    </td >
  );
};

const createDataState = (data, dataState) => {
  return {
    result: process(data.slice(0), dataState),
    dataState: dataState,
  };
};

// Enable an element to be clicked using the spacebar key
// Required for both the navigation buttons on the grid (such as previous and next page)
// as well as the page number buttons since by default they can only be clicked with the enter key
function spaceBarClick(e) {
  if (e.key === ' ') {
    e.target.click();
  }
}

const ReportGrid = (props) => {
  const [showLevel, setShowLevel] = useState(false);
  const [showReportCycle, setShowReportCycle] = useState(false);
  const appContext = React.useContext(AppContext);
  const [showTestlet, setShowTestlet] = useState(false);

  let reportAudioFiles = [];
  // DE22508: convert grades to numbers to ensure proper sorting
  const data = ConvertGradeToNumeric(
    props.item
      .sort((a, b) => {
        let fa = a.schoolyear,
          fb = b.schoolyear;
        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      })
      .reverse());

  const [gridState, setGridState] = useState(
    createDataState(data, {
      take: 8,
      skip: 0,
      sort: [],
    })
  );

  useEffect(() => {
    const data = props.item;
    setShowReportCycle(data[0].reportcycle ? true : false);
    setShowLevel(data[0].level ? true : false);
    setShowLevel(data[0].level ? true : false);
    sortChange({ sort: [{ field: "schoolyear", dir: "desc" }] }, data);
    setShowTestlet(data[0].testcollectionname ? true : false);
    let audioJson = props.audioFiles;
    if (audioJson) {
      audioJson.forEach((item) => {
        reportAudioFiles.push(item);
      });
    }
    appContext.dispatch({
      type: "SET_AUDIOFILES",
      data: { "reportAudioFiles": reportAudioFiles, "currentreportyear": props.currentreportyear },
    });
  }, [props.item]);

  useEffect(() => {
    modifyPagingAccessibility();
  })
  useEffect(() => {
    enableSpaceBarNavigation();
  }, [])

  const ColumnMenuCheckboxFilter = (props) => {
    return (
      <div>
        <GridColumnMenuCheckboxFilter {...props} data={data} expanded={true} />
      </div>
    );
  };

  const pageChange = (event) => {
    sortChange({ sort: gridState.dataState.sort }, data, {
      take: event.page.take,
      skip: event.page.skip,
    });
  };

  const sortChange = (event, data, filters = null) => {
    // When the sorting is done by clicking on column header, the data is set to undefined, so we need to set the data from props. 
    if (data === undefined || data === null) {
      data = props.item;
    }
    const reportCycle = JSON.parse(
      appContext.data.staticText.parentportal_reportcycle_order
    );

    if (event.sort && event.sort[0] && event.sort[0].field === "schoolyear") {
      const sorted = data.sort((a, b) => {
        const dir = event.sort[0].dir;
        if (dir === "desc") {
          if (a.schoolyear < b.schoolyear) return -1;
          if (a.schoolyear > b.schoolyear) return 1;
          if (
            reportCycle.indexOf(a.reportcycle) <
            reportCycle.indexOf(b.reportcycle)
          )
            return -1;
          if (
            reportCycle.indexOf(a.reportcycle) >
            reportCycle.indexOf(b.reportcycle)
          )
            return 1;
          if (
            reportCycle.indexOf(a.reportcycle) ===
            reportCycle.indexOf(b.reportcycle)
          )
            return 0;
        } else {
          if (a.schoolyear > b.schoolyear) return -1;
          if (a.schoolyear < b.schoolyear) return 1;
          if (
            reportCycle.indexOf(a.reportcycle) >
            reportCycle.indexOf(b.reportcycle)
          )
            return -1;
          if (
            reportCycle.indexOf(a.reportcycle) <
            reportCycle.indexOf(b.reportcycle)
          )
            return -1;
          if (
            reportCycle.indexOf(a.reportcycle) ===
            reportCycle.indexOf(b.reportcycle)
          )
            return 0;
        }
        return 0;
      });
      const dataState = filters ? filters : gridState.dataState;
      dataState.sort = event.sort;
      // DE22508: convert grades to numbers to ensure proper sorting
      setGridState(createDataState(ConvertGradeToNumeric(sorted), dataState));
    } else {
      // DE22508: convert grades to numbers to ensure proper sorting
      const data = ConvertGradeToNumeric(GetReports(gridState.result.data, event.sort));
      const dataState = filters ? filters : gridState.dataState;
      dataState.sort = event.sort;
      setGridState(createDataState(data, dataState));

    }
  };

  const dataStateChange = (event) => {
    // data in this case refers to the original data sent to populate the grid.
    // event.dataState contains the list of filters and sorting to apply to this list of data.
    // It is important we send the original set of data to be filtered and not the current data
    // in the grid or else we effectively lose the ability to remove filters when we are done with them.
    setGridState(createDataState(data, event.dataState));
  };

  // Do not allow disabled buttons (such as the previous page button when on the first page) to be tabbable
  // We may need to reenable previously disabled buttons when the page changes (e.g. back page button when on the second page)
  // so this function is called after each render
  const modifyPagingAccessibility = () => {
    for (var element of document.getElementsByClassName('k-pager-nav')) {
      element.removeEventListener('keydown', spaceBarClick)
      if (element.classList.contains('k-disabled')) {
        element.tabIndex = -1;
      }
      else {
        element.tabIndex = 0;
        element.addEventListener('keydown', spaceBarClick)
      }
    }
  }

  // enable the page number buttons to be clicked using the spacebar, called once on the initial render
  const enableSpaceBarNavigation = () => {
    const pagerNumbers = document.getElementsByClassName('k-pager-numbers')[0];
    // if there is only one page, the '1' button on the bottom of the grid should not be tabbable
    if (pagerNumbers.children.length === 1) {
      pagerNumbers.children[0].children[0].tabIndex = -1;
    }
    else {
      for (var element of pagerNumbers.children) {
        var linkElement = element.children[0];
        linkElement.addEventListener('keydown', spaceBarClick);
      }
    }
  }

  return (
    <Row>
      <Col>
        <Grid
          data={gridState.result}
          {...gridState.dataState}
          onDataStateChange={dataStateChange}
          pageable={true}
          pageSize={8}
          sortable
          sort={gridState.dataState.sort}
          onSortChange={(e) => sortChange(e)}
          onPageChange={(e) => pageChange(e)}
          skip={gridState.dataState.skip}
          take={gridState.dataState.take}
        >
          <Column
            field="schoolyear"
            title="Year"
            columnMenu={ColumnMenuCheckboxFilter}
          />
          <Column
            field="schoolname"
            title="School Name"
            columnMenu={ColumnMenuCheckboxFilter}
          />
          <Column
            field="grade"
            title="Grade"
            columnMenu={ColumnMenuCheckboxFilter}
          />
          {showLevel && (
            <Column
              field="level"
              title="Proficiency Level"
              columnMenu={ColumnMenuCheckboxFilter}
            />
          )}
          {showReportCycle && (
            <Column
              field="reportcycle"
              title="Report Cycle"
              columnMenu={ColumnMenuCheckboxFilter}
            />
          )}
          {showTestlet && (
            <Column
              field="testcollectionname"
              title="Testlet"
              columnMenu={ColumnMenuCheckboxFilter}
            />
          )}
          <Column field="filepath" title="Download Report" cell={CustomCell} />
        </Grid>
      </Col>
    </Row>
  );
};

export default ReportGrid;
